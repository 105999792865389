import { Cancel, Language, Menu, Search } from '@mui/icons-material'
import { AppBar, Avatar, Box, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { sideBarWidth } from '../utils'
import CustomUserButton from './CustomUserbutton'
import { useUser } from '@clerk/clerk-react'
import { useDispatch } from 'react-redux'
import { updateSearch } from '../slices/notesSlice'

const debouncDelay = 500;

const Header = ({
  drawerOpen,
  setDrawerOpen,
  setMobileOpen
}) => {
  const { user } = useUser();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const isSuperAdmin = user?.publicMetadata?.isSuperAdmin || false;

  useEffect(()=>{
    const handler = setTimeout(()=>{
      dispatch(updateSearch(search));
    },debouncDelay)
    return ()=> clearTimeout(handler)
  },[search])
  return (
    <AppBar variant='outlined' sx={{
      backgroundColor: 'white',
      border: 'none',
      width: { md: `calc(100% - ${drawerOpen ? sideBarWidth : 0}px)`, sm: '100%', xs: '100%' },
      transition: '0.2s ease-in-out'
    }} position='fixed'>
      <Toolbar>
        <Box width='100%' component='div' display='flex' justifyContent='space-between' alignItems='center' padding={'15px'}>
          <Box component='div'  display='flex' columnGap='25px' alignItems='center'>
            <Menu onClick={() => { setDrawerOpen((open) => !open) }} fontSize='large'
              sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
            <Menu onClick={() => { setMobileOpen((open) => !open) }} fontSize='large'
              sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} />
              <TextField
                size='small'
                sx={{
                  backgroundColor: '#F5F6FA',
                  width: '400px'
                }}
                variant='outlined' 
                placeholder= {
                  isSuperAdmin
                    ? "Search by CC, Author Name, Author Email"
                    : "Search ..."
                }
                value={search}
                onChange={(e)=> setSearch(e.target.value)}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {
                          search && search.length > 0 && 
                          (
                            <IconButton onClick={()=>setSearch('')}>
                          <Cancel />
                        </IconButton>
                          ) 
                        }
                      </InputAdornment>
                    )
                  }
                }}
              />
          </Box>
          <Box component='div' display='flex' columnGap='25px' alignItems='center'>
            <Box component='div' display="flex" alignItems="center" gap={2}>
              <CustomUserButton />
              <Typography color='#404040' fontSize="14px" fontWeight={700} marginTop="5px">
                {user.fullName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header